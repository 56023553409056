import { ApiError } from "./ApiError";
import { Client } from "./baseurl";


export const getSellerPurchase = async(code: string) => {
    try {
        const response = await Client.get(`/seller/purchase/${code}`);
        return response.data;    
    } catch (error:any) {
        let message = "Ocurrio un error";
        let details = undefined;
        if (error!.response!.data!.message) {
            message = error!.response!.data!.message;
            details = error!.response!.data!.details;
        }
        throw new ApiError(message, details);
    }
}

export const sendCardNumberFinishPurchase = async(cardNumber: string, purchaseCode: string, deviceId: string) => {
    try {
        const response = await Client.get(`/seller/card-number?number=${cardNumber}&code=${purchaseCode}&deviceId=${deviceId}`);
        return response.data;
    } catch (error: any) {
        let message = "Ocurrio un error";
        let details = undefined;
        if (error!.response!.data!.message) {
            message = error!.response!.data!.message;
            details = error!.response!.data!.details;
        }
        throw new ApiError(message, details);
    }
}
