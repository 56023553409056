export const parseJWt = (token:string) =>{
    if(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }
    return null;
}

export type CustomField = {
    id: string,
    nombre: string,
    tipo_campo: string,
    mandatorio: string,
    tipo_formulario: string,
    attribute: string,
    elemento_lista_desplegable: string
};

export const parseJsonStringToArray = (data: null | string):any[] => {
    if(data){
        try {
            return JSON.parse(data);
        } catch (error) {
            console.log(error);
        }
    }
    return [];
}

export const hasTypeForm = (data: null | string, typeForm: string):boolean => {
    if(data){
        try {
            const arrayData = JSON.parse(data);
            // Verifica si al menos un objeto en el array tiene el typeForm especificado
            return arrayData.some((item: { tipo_formulario: string }) => item.tipo_formulario === typeForm);
        } catch (error) {
            console.log(error);
        }
    }
    return false;
}

export const parseCustomFields = (data: null | string, typeForm: string) => {
    if(data){
        try {
            //parseo
            const customFields: CustomField[] = JSON.parse(data);
            //filtro los que me importan para el tipo de formulario
            const currentCustomFields = customFields.filter(customField => customField.tipo_formulario === typeForm);
            
            //transformo los filtrados en un array
            const arrayCustomFields: string[] = currentCustomFields.map(customField => {
                return customField.attribute;
            });

            //luego transformo todo en un objeto que sera devuelto para extrapolar en el formulario
            return arrayCustomFields.reduce((acc, current) => {
                acc[current] = '';
                return acc;
              }, {} as { [key: string]: string });
        } catch (error) {
            console.log(error);
        }
    }
    return null;
}