import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import '../sass/productores_salas.scss';
import '../sass/general.scss';
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { getLangValue } from '../../api/config';

interface IconoProps {
    color: string;
}

const Icono = (props: IconoProps) => {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 300.1 300" xmlSpace="preserve">
            <path d="M228.1,98.4c4.5,4.5,4.5,11.8,0,16.3l-89.6,89.6c-3.9,3.9-10,4.4-14.5,1.5c-0.6-0.4-1.2-0.9-1.8-1.5l-48.9-48.9
                c-4.5-4.5-4.5-11.8,0-16.3c4.5-4.5,11.8-4.5,16.3,0l40.8,40.8l81.5-81.5C216.3,93.9,223.6,93.9,228.1,98.4z M300.1,150
                c0,82.7-67.3,150-150,150c-82.7,0-150-67.3-150-150c0-82.7,67.3-150,150-150C232.8,0,300.1,67.3,300.1,150z M277,150
                c0-70-56.9-126.9-126.9-126.9C80.1,23.1,23.2,80,23.2,150c0,70,56.9,126.9,126.9,126.9C220.1,276.9,277,220,277,150z"
                style={{ fill: props.color }} />
        </svg>
    );
}
const SmartTicket = () => {
    const [productYSalasTitle] = useState<string>(`${getLangValue("product-salas-title")}`);

    const handleEnd = ({ pauseResume }: { pauseResume: () => void }) => {
        pauseResume();
    };

    useEffect(() => {

        document.title = productYSalasTitle;

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500)

        const handleScroll = () => {
            const divDestino = document.getElementById('seccion_4');
            const fixedElement = document.getElementById('scroll-element');

            if (!divDestino || !fixedElement) return;

            const divDestinoTop = divDestino.getBoundingClientRect().top;
            const fixedElementHeight = fixedElement.clientHeight;
            const isFixedElementAboveDiv = divDestinoTop >= fixedElementHeight;

            
          };

          window.addEventListener('scroll', handleScroll);

          return () => {
            window.removeEventListener('scroll', handleScroll);
          };

    }, [])

    return (
        <Col className='text-start productores-y-salas'>
            <section id="seccion_1" className="back_1">
                
                <h1 className="que-es text_2">Entradaweb QR y Smart Ticket</h1>
                <h2 className="h1 text_2 ps-5 ms-2 pe-5">Preguntas Frecuentes</h2>
                <h2 className="text_2 mt-4">Qué es QR y Smart Ticket?</h2>
                <Row>
                    <Col xs={2} className='pe-0 pt-1'>
                        <Icono color='#380AB4' />
                    </Col>
                    <Col xs={10}>
                            <p className="text_3">Entradaweb soporta múltiples maneras de entregar las entradas compradas en nuestra sitio. Podemos enviarte entradas con QR estático o podemos utilizar Smart Ticket
Un Smart Ticket es una entrada o ticket electrónico único y seguro que puedes recibir y almacenar en nuestra app hasta el momento de usarlo o hasta que lo transfieras a otra persona.
Esta app es sólo para administrar tus entradas compradas o recibidas como invitación  a través de los servicios de Entradaweb.</p>
                    
                    </Col>
                </Row>
                    <h2 className="h1 text_2 ms-2 pe-5">Cómo funciona el Smart Ticket de Entradaweb?</h2>
                <Row>
                    <Col xs={10}>
                            <p className='min-font-size text-end'>
                            Cuando compres tus entradas o tickets para un evento (y si dicho evento está configurado para entregar las entradas como Smart Ticket) vas a recibir en tu correo electrónico una confirmación de compra y verás los tickets en nuestra app instalada en tu celular.
                            También de acuerdo a cómo esté configurado el uso de Smart Ticket en el evento, podrás transferir un número determinado de veces tus tickets o entradas.
                            El QR que te permite el acceso al evento, y por razones de seguridad, será visible sólo el día del evento. Esta funcionalidad está disponible aunque tu celular no esté conectado a internet.

                            </p>
                    </Col>
                    <Col xs={2}>
                        <Icono color='#380AB4' />
                    </Col>
                </Row>
            </section>
            <section id="seccion_2" className="back_2">
                <h1 className="text_1">Cómo instalo Entradaweb QR y Smart Ticket?</h1>
                <Row>
                    <Col xs={3} className='d-flex flex-column align-items-center'>
                        <Icono color='#9a83d7' />
                        <div className='linea  mt-2'></div>
                    </Col>
                    <Col xs={9}>
                            <p className='text_1'>Desde tu celular instalas la app desde la Play Store (Android) o desde la App Store (IPhone). Abres la app y te solicitará tu email (el mismo que utilizaste en nuestro sitio para comprar tus entradas). Acto seguida vas a recibir un email donde te enviamos un Código de Verificación que deberás ingresar para completar el acceso a la App.
                            Puede ocurrir también que si no tenemos registrados tus datos te solicitemos Nombre y Apellido y DNI. </p>
                    </Col>
                </Row>
            </section>
            <section id="seccion_3" className="back_1 ps-4">
                    <h1 className="h1 text_2 text-end  pe-4 me-2">Cómo transfiero Entradas?</h1>
                    <p className='min-font-size text-justify'>
                        Si la opción Transferir no está disponible para tu evento, entonces pueden ocurrir dos cosas:
                        1. el evento no permite transferencia de entradas.
                        2. la cantidad de transferencias permitidas ya fue utilizada. 
                        Cada Productor u Organizador decide para su evento si acepta o no transferencia de entradas y además fija la cantidad de transferencias que se pueden realizar.
                    </p>
                <Row>
                    <Col>
                        <h1 className="text_1" style={{"color": "#380ab4"}}>Acceso al evento</h1>
                        <div className='ms-5 ps-2 mt-5 pt-2'><Icono color='#FF6600' /></div>
                    </Col>
                    <Col>
                        <p className='min-font-size'>
                        Como ya dijimos, la app contiene tus entradas y el mismo día del evento generará para cada una de ellas el QR que te permitirá acceder al mismo. Dicho QR es único, irrepetible y sólo estará disponible en la app en tu celular.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    
                        <p className='min-font-size text-end'>
                                Si extravías o te roban tu celular, no te preocupes que tus tickets o entradas te estarán esperando en nuestra app. 
                                Solamente inicia sesión en cualquier otro celular con tu Usuario y la sesión anterior (que tenías abierta en el dispositivo que ya no tienes) se cerrará automáticamente.
                                Como medida de seguridad recibirás en tu casilla de correo un código de verificación. Lo ingresas en la app para completar tu inicio de sesión y accederás nuevamente a tus entradas.
                        </p>
                    </Col>
                    <Col>
                        <h1 className="text_1" style={{"color": "#380ab4"}}>Pérdida de celular</h1>
                    </Col>
                </Row>
            </section>
        </Col>
    );
};

export default SmartTicket;