import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { datosCompra } from '../../../types/types';
import DetalleCompra from '../compra/DetalleCompra';
import TipoPagoTarjeta from '../compra/TipoPagoTarjeta';
import TipoPagoCash from '../compra/TipoPagoCash';
import TipoPagoDirectPaid from '../compra/TipoPagoDirectPaid';
import {Button, Col, Row } from 'reactstrap';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import '../../sass/evento_seleccionado/paso_cuatro.scss';
import TituloEvento from './TituloEvento';
import TipoPagoRedsys from '../compra/TipoPagoRedsys';
import { fetchDetalleCompra } from '../../../reducer/events-reducer';

type configObjectDatosComprados = {
    eventsReducer: {
        detalleCompra: datosCompra;
        cardNumber: string | null;
    };
};

const PasoCuatroEvento = ({ detalleCompra, cardNumber }: { detalleCompra: datosCompra, cardNumber: string | null  }) => {

    const urlParams = new URLSearchParams(window.location.search);
    const typeParam = urlParams.get('type');
    const [viewComissionText, setViewComissionText] = useState<boolean>(true);

    useEffect(() => {

        if(typeParam !== 'debitCreditCard') {
            handleViewComissionText();
        }

        fetchDetalleCompra();
    }, [fetchDetalleCompra]);

    const handleViewComissionText = (): void => {
        setViewComissionText(false);
    }

    return (
        <>
            <div className='compra-paso-cuatro'>
                <TituloEvento paso={4}/>
                <div className='mb-4'></div>
                {typeParam === 'debitCreditCard' && <TipoPagoTarjeta handleViewComissionText={handleViewComissionText} presetedCardNumber={cardNumber} purchaseCode={null} />}
                {typeParam === 'cash' && <TipoPagoCash />}
                {typeParam === 'directPaid' && <TipoPagoDirectPaid />}
                {typeParam === 'redsys' && <TipoPagoRedsys />}
                {detalleCompra &&
                <Row className="mt-4 mb-4">
                    <Col>
                        <DetalleCompra pasoTres={true} viewComissionText={viewComissionText} />
                    </Col>
                </Row>
                }
                <div className='d-flex justify-content-between steps-buttons flex-column'>
                <Button className='back_2 mt-4' onClick={() => {
                            window.history.go(-1);
                        }}><ArrowBackIosIcon /> Paso Anterior</Button>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: configObjectDatosComprados) => {
    return {
        detalleCompra: state.eventsReducer.detalleCompra,
        cardNumber: state.eventsReducer.cardNumber
    }
};

export default connect(
    mapStateToProps,
    fetchDetalleCompra
)(PasoCuatroEvento);
