import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import '../sass/productores_salas.scss';
import '../sass/general.scss';
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';
import CountUp from 'react-countup';
import { VisibleType } from 'react-animate-on-scroll';
import { getLangValue } from '../../api/config';
import { getEmailComercial, getTelefonoComercial } from '../../api/helpText';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

const ProductoresYSalas = () => {
    const [isFixedElementVisible, setFixedElementVisible] = useState(true);
    const [iniciarCounter, setiniciarCounter] = useState(false);
    const [urlBoleteria] = useState<string>(`${getLangValue("mi")}`);
    const [urlS3] = useState<string>(`${getLangValue("s3")}`);
    const [lang] = useState<string>(`${getLangValue("lang")}`);
    const [telefonoComercial, setTelefonoComercial] = useState<string>('');
    const [emailComercial, setEmailComercial] = useState<string>('');
    const [imgInfra] = useState<string>(`${getLangValue("about-us-img-infra")}`);
    const [imgContable] = useState<string>(`${getLangValue("about-us-img-contable")}`);
    const [imgCeo] = useState<string>(`${getLangValue("about-us-img-ceo")}`);
    const [productYSalasTitle] = useState<string>(`${getLangValue("product-salas-title")}`);

    const colbacIn = (visible: VisibleType) => {
        setiniciarCounter(true)
        return {}
    }

    const handleEnd = ({ pauseResume }: { pauseResume: () => void }) => {
        pauseResume();
    };

    useEffect(()=>{

        let alternateAr = document.getElementById("alternate_ar") as HTMLLinkElement;
        alternateAr.href = "https://www.entradaweb.com.ar/productores-y-salas";

        let alternateEs = document.getElementById("alternate_es") as HTMLLinkElement;
        alternateEs.href = "https://www.entradaweb.es/productores-y-salas";

        let alternateDefault = document.getElementById("alternate_default") as HTMLLinkElement;
        alternateDefault.href = "https://www.entradaweb.com/productores-y-salas";

        document.title = productYSalasTitle;

        (async () => {
            const res = await getTelefonoComercial();
            setTelefonoComercial(res.text);
        })();

        (async () => {
            const res = await getEmailComercial();
            setEmailComercial(res.text);
        })();

        setTimeout(()=>{
            window.scrollTo(0, 0);
        },500)
        const handleScroll = () => {
            const divDestino = document.getElementById('quienes-hacemos');
            const fixedElement = document.getElementById('scroll-element');

            if (!divDestino || !fixedElement) return;

            const divDestinoTop = divDestino.getBoundingClientRect().top;
            const fixedElementHeight = fixedElement.clientHeight;
            const isFixedElementAboveDiv = divDestinoTop >= fixedElementHeight;

            setFixedElementVisible(isFixedElementAboveDiv);
          };

          window.addEventListener('scroll', handleScroll);

          return () => {
            window.removeEventListener('scroll', handleScroll);
          };

    },[])

    return (
        <div className='productores-y-salas'>
            <Container>

                <Col className='text-start mt-4' md={{ offset: 2, size: 8 }}>
                    <ScrollAnimation animateIn='fadeIn'>
                        <h1 className="text_2 mb-4"><FormattedMessage id="what_is_it_title" /></h1>
                    </ScrollAnimation>
                    <Row>
                        <Col md={{ offset: 3, size: 9 }}>
                            <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
                                <p className="text_3"><FormattedMessage id="what_is_it_paragraph_1" /></p>
                            </ScrollAnimation>
                            <ScrollAnimation delay={500} animateIn='bounceInRight' animateOut='bounceOutLeft'>
                                <p>
                                    <FormattedMessage id="what_is_it_paragraph_2" />
                                </p>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <Row className='mt-3 mb-4'>
                        <Col md={7} className='text-end'>
                            <ScrollAnimation delay={750} animateIn='bounceInLeft' animateOut='bounceOutRight'>
                                <h2 className="text_2">Simple y sencillo para que uses tu tiempo en lo que más importa</h2>
                            </ScrollAnimation>
                        </Col>
                        <Col>
                            <ScrollAnimation delay={850} animateIn='bounceInRight' animateOut='bounceOutLeft'>
                                <p>
                                    Hacemos de la simplicidad una de nuestras metas. Te brindamos herramientas simples y eficientes, muy fáciles de entender y usar. Publicar tu evento en Entradaweb, aunque nunca hayas usado nuestra plataforma, te llevará solo unos pocos minutos, ya sea que uses Autogestión o nuestra Mesa de Ayuda. Lo mismo para los compradores de tus entradas, en 2 minutos ya obtienen sus tickets.
                                </p>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Col>
            </Container>
            <Col className='back_2'>
                <Container className='back_2 pt-3 pb-3 position-relative'>
                    <ScrollAnimation animateIn='fadeIn' className='position-absolute' style={{ "width": "25%", "left": "-10%", "top": "90px" }}>
                        <img src="/images/ventajas-back.svg" alt="" style={{ "opacity": "0.4" }} />
                    </ScrollAnimation>
                    <Col md={{ offset: 2, size: 8 }}>
                        <ScrollAnimation animateIn='fadeIn'>
                            <h2 className="text_1 col-md-8 mt-3 mb-5">Ventajas únicas de Trabajar con Nosotros</h2>
                        </ScrollAnimation>
                        <Row>
                            <Col>
                                <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutRight'>
                                    <p className='text_1'><FormattedMessage id="advantages_paragraph_1_1"/></p>
                                    <p className='text_1'><FormattedMessage id="advantages_paragraph_1_2" /></p>
                                    <p className='text_1'><FormattedMessage id="advantages_paragraph_1_3" /></p>
                                    {lang === 'es_AR' && <p className='text_1'><FormattedMessage id="advantages_paragraph_1_4" /></p>}
                                    {lang === 'es_AR' && <p className='text_1'><FormattedMessage id="advantages_paragraph_1_5" /></p>}
                                </ScrollAnimation>
                            </Col>
                            <Col>
                                <ScrollAnimation delay={100} animateIn='bounceInRight' animateOut='bounceOutLeft'>
                                    <p className='text_1'><FormattedMessage id="advantages_paragraph_2_1" /></p>
                                    <p className='text_1'><FormattedMessage id="advantages_paragraph_2_2" /></p>
                                    <p className='text_1'>Nuestra plataforma trabaja con entradas o tickets con QR estáticos o con QR dinámicos a través de nuestra app <a href='smart-ticket' className="link-smart-link">Entradaweb QR y Smart Ticket.</a></p>
                                    <p className='text_1'><FormattedMessage id="advantages_paragraph_2_3" /></p>
                                    {lang === 'es_AR' && <p className='text_1'><FormattedMessage id="advantages_paragraph_2_4" /></p>}
                                </ScrollAnimation>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </Col>
            <Container>
                <Col>
                    <Container>
                        <Col md={{ offset: 2, size: 9 }} className='mt-5'>
                            <Row>
                                <Col className='text-end' md='5'>
                                    <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutRight'>
                                        <h3 className="text_2"><FormattedMessage id="we_are_ready_title" /></h3>
                                    </ScrollAnimation>
                                </Col>
                                <Col>
                                    <ScrollAnimation delay={100} animateIn='bounceInRight' animateOut='bounceOutLeft'>
                                        <p>
                                            <FormattedMessage id="we_are_ready_paragraph_1" />
                                        </p>
                                    </ScrollAnimation>
                                    {lang === 'es_AR' && <>
                                    <ScrollAnimation delay={200} animateIn='bounceInRight' animateOut='bounceOutLeft'>
                                        <h4 className="text_3 mb-3">¡Fiesta Nacional de la Vendimia x 4 y contando!</h4>
                                    </ScrollAnimation></>}
                                    <ScrollAnimation delay={300} animateIn='bounceInRight' animateOut='bounceOutLeft'>
                                        <p>
                                            <FormattedMessage id="we_are_ready_paragraph_2" />
                                        </p>
                                    </ScrollAnimation>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col md={5} className='text-end'>
                                    <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutRight'>
                                        <p>
                                            <FormattedMessage id="publish_our_platforms_paragraph" />
                                        </p>
                                        <a href={"https://api.whatsapp.com/send/?phone=" + telefonoComercial + "&text=Hola&type=phone_number&app_absent=0"} className="text_3 text-center">Mesa de ayuda<br/> a productores</a>
                                        <a href={urlBoleteria} className="text_3 text-center">Ir a <br/>autogestión</a>
                                    </ScrollAnimation>
                                </Col>
                                <Col md={5}>
                                    <ScrollAnimation delay={100} animateIn='bounceInRight' animateOut='bounceOutLeft'>
                                        <h3 className="text_2"><FormattedMessage id="publish_our_platforms_title" /></h3>
                                    </ScrollAnimation>
                                </Col>
                            </Row>
                            <Row className='mt-3 mb-4 pb-2'>
                                <Col className='text-end'>
                                    <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutRight'>
                                        <h3 className="text_2"><FormattedMessage id="ticket_office_title" /></h3>
                                    </ScrollAnimation>
                                </Col>
                                <Col>
                                    <ScrollAnimation delay={100} animateIn='bounceInRight' animateOut='bounceOutLeft'>
                                        <p><FormattedMessage id="ticket_office_paragraph_1" /></p>
                                        <p><FormattedMessage id="ticket_office_paragraph_2" /></p>
                                    </ScrollAnimation>
                                </Col>
                            </Row>
                        </Col>
                    </Container>
                </Col>
            </Container>
            {lang === 'es_AR' ?
            <div className="back_3 pt-4">
                <Container className='mt-2'>
                    <Col className='d-flex justify-content-between align-items-center' md={{ offset: 2, size: 8 }}>
                        <ScrollAnimation animateIn='fadeIn' afterAnimatedIn={colbacIn}>
                            <div className="icon_box">
                                <img src="/images/eventos-icon.svg" alt="" />
                                {iniciarCounter && <h3>+<CountUp end={20000} separator='.'/></h3>}
                                <p>Eventos Vendidos</p>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation delay={100} animateIn='fadeIn'>
                            <div className="icon_box">
                                <img src="images/entradas-icon.svg" alt="" className='icon-entradas-vendidas' />
                                {iniciarCounter && <h3 className='icon-box-value'>
                                    <CountUp
                                        end={2}
                                        decimal={'.'}
                                        onEnd={handleEnd}
                                    />{' '}MILLONES</h3>}
                                <p>Entradas Vendidas</p>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation delay={200} animateIn='fadeIn'>
                            <div className="icon_box">
                                <img src="images/positivo-icon.svg" alt="" />
                                {iniciarCounter && <h3><CountUp end={99} /> %</h3>}
                                <p>Satisfacción Usuarios</p>
                            </div>
                        </ScrollAnimation>
                    </Col>
                </Container>
            </div> : <div className="back_3 pt-4">
                <Container className='mt-2'></Container></div>}

            <div className="scroll" id='scroll-element' style={{"display": isFixedElementVisible ? "block": "none"}}></div>
            <Container id='quienes-hacemos'>
                <Col className='mb-4'>
                    <Row className='mt-2'>
                        <Col md={{ offset: 2, size: 7 }} className='mt-4'>
                            <ScrollAnimation animateIn='fadeIn'>
                                <h2 className="text_2"><FormattedMessage id="about_us_title"/></h2>
                                <p><FormattedMessage id="who_we_do"/></p>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 8, offset: 2 }} className='d-flex justify-content-between'>
                            {lang === 'es_AR' && 
                            <ScrollAnimation delay={100} animateIn='fadeIn'>
                                <div className="face">
                                    <img src={urlS3 + imgCeo} className="img-fluid" alt='' />
                                    <h4 className='text_2'>Miguel</h4>
                                    <p>Responsable General <br />(24 x 7 te atiende en el<br /> <a href={"https://api.whatsapp.com/send/?phone=" + telefonoComercial + "&text=Hola&type=phone_number&app_absent=0"} className="phone-link">{telefonoComercial}</a>)</p>
                                </div>
                            </ScrollAnimation>}
                            {lang === 'es_ES' && 
                            <ScrollAnimation delay={100} animateIn='fadeIn'>
                                <div className="face">
                                    <img src={urlS3 + imgCeo} className="img-fluid" alt='' />
                                    <h4 className='text_2'>Alejandro</h4>
                                    <p>Responsable General España<br /><a className="email-link" href={"mailto:" + emailComercial}>{emailComercial}</a>Mov: <a href={"https://api.whatsapp.com/send/?phone=" + telefonoComercial + "&text=Hola&type=phone_number&app_absent=0"} className="phone-link">{telefonoComercial}</a></p>
                                </div>
                            </ScrollAnimation>}
                            <ScrollAnimation delay={200} animateIn='fadeIn'>
                                <div className="face">
                                    <img src={urlS3 + imgContable} className="img-fluid" alt='Verónica' />
                                    <h4 className='text_2'>Verónica</h4>
                                    <p>Responsable Financiera</p>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation delay={300} animateIn='fadeIn'>
                                <div className="face">
                                    <img src={urlS3 + imgInfra} className="img-fluid" alt='Marcelo' />
                                    <h4 className='text_2'>Marcelo</h4>
                                    <p>Responsable de Infraestructura Informática</p>
                                </div>
                            </ScrollAnimation>
                        </Col>
                    </Row>

                </Col>
            </Container>

        </div>

    );
};

export default ProductoresYSalas;