import React, { useEffect } from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '../../utilities/TwitterIcon';
import FacebookIcon from '@mui/icons-material/Facebook';
import { connect } from 'react-redux';
import { fetchTextoAyuda } from '../../reducer/config-reducer';
import { Link } from 'react-router-dom';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import '../sass/general.scss';
import {
    Col,
    Button
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import '../sass/footer.scss'
import { useLocation } from 'react-router-dom';
import { getLangValue } from '../../api/config';

interface configObjectFooter {
    configReducer: {
        textoAyuda: ''
    }
}

interface propiedades {
    fetchTextoAyuda: (txt:string, tit:string) => void;
    textoAyuda: string
}

const Footer = (props: propiedades) => {
    const location = useLocation();
    const hash = window.location.hash;

    useEffect(() => {
        if (hash === "#subpagina-privacidad") {
            handleAyuda('privacidad', 'privacy_policy_modal_title')
        } else if (hash === "#subpagina-condiciones-legales") {
            handleAyuda('condiciones-legales', 'terms_n_conditions_modal_title')
        } else if (hash === "#subpagina-defensa-del-consumidor") {
            handleAyuda('defensa-del-consumidor', 'consumer_defense_modal_title')
        }
      }, [hash]);

    const handleAyuda = (txt: string, title:string) => {
        props.fetchTextoAyuda(txt, title)
    }

    return (
        <footer className='footer'>
            <div className='social-wrapper'>
                <div className='d-flex align-items-center justify-content-center h-100'>
                    <a href={getLangValue("facebook_url")} target="_blank" rel='noreferrer'><Button><FacebookIcon className='icon' /></Button></a>
                    <a href={getLangValue("twitter_url")} target="_blank" rel='noreferrer'><Button><TwitterIcon className='icon twitter-icon' /></Button></a>
                    <a href={getLangValue("instagran_url")} target='_blank' rel='noreferrer'><Button><InstagramIcon className='icon ig' /></Button></a>
                    {window.location.pathname !== '/' && <div className='rounded-circle down-icon float-end mt-2 position-absolute end-0 me-3' onClick={()=>{
                        window.scroll(0,0);
                    }}>
                        <KeyboardArrowUpIcon />
                    </div>}
                </div>
            </div>
            <div className='w-100 text-center'>
                <Col className='sub-footer w-100'>
                    <p className='h5 mb-1'>
                    {location.pathname === '/productores-y-salas' ?
                        <>Productores y Salas</>
                       :<Link to='/productores-y-salas' className='btn-link'>Productores y Salas</Link>}
                    </p>
                    <p className='m-0 p-0'>
                        <Button onClick={() => { handleAyuda('privacidad', 'privacy_policy_modal_title') }} color='link'> <FormattedMessage id="privacy_policy" /></Button> |
                        <Button onClick={() => { handleAyuda('condiciones-legales', 'terms_n_conditions_modal_title') }} color='link'><FormattedMessage id="terms_n_conditions" /></Button> |
                        <Button onClick={() => { handleAyuda('defensa-del-consumidor', 'consumer_defense_modal_title') }} color='link'><FormattedMessage id="consumer_defense" /></Button>
                    </p>
                    <p><FormattedMessage id="marca_registrada"/><br/><FormattedMessage id="todos_los_derechos_reservados"/></p>
                </Col>
            </div>
        </footer>
    );
};

const mapStateToProps = (state: configObjectFooter) => {
    return {
        textoAyuda: state.configReducer.textoAyuda
    }
}
export default connect(
    mapStateToProps,
    { fetchTextoAyuda }
)(Footer);
