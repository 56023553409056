import React, { useEffect, useState } from 'react';
import Spinner from '../components/Spinner';
import { Col, Container, Row } from 'reactstrap';
import DateRangeIcon from '@mui/icons-material/DateRange';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import { Table } from 'antd';
import { getSellerPurchase } from '../../api/sellerPurchase';
import { getLangValue } from '../../api/config';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BoyOutlinedIcon from '@mui/icons-material/BoyOutlined';
import '../sass/pasos_compra.scss'
import '../sass/evento_seleccionado/paso_cuatro.scss';
import '../sass/evento_seleccionado.scss';
import '../sass/general.scss';
import '../sass/barra_superior.scss';
import '../sass/terminar_compra_vendedor.scss';
import TipoPagoTarjeta from '../components/compra/TipoPagoTarjeta';

const TerminarCompraVendedor = () => {
    const [sellerPurchase, setSellerPurchase] = useState<any | null>(null);
    const [errorMessage, setErrorMessage] = useState<any | null>(null);
    const lang = getLangValue('lang');
    const [purchaseCode, setPurchaseCode] = useState<string | null>("");
    const [viewComissionText, setViewComissionText] = useState<boolean>(true);
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        setPurchaseCode(code);
        (async () => {
            if(code) {
                try {
                    const response = await getSellerPurchase(code);
                    if (response) {
                        setSellerPurchase(response);
                    }   
                } catch (error: any) {
                    setErrorMessage(error!.message)
                }
            }
        })()
    }, [])

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
        },
        {
            title: 'Sector',
            dataIndex: 'sector',
            key: 'sector',
        },
        {
            title: 'LUGAR',
            dataIndex: 'place',
            key: 'place',
        },
        {
            title: 'Precio',
            dataIndex: 'price',
            key: 'price'
        }
    ];

    const data = sellerPurchase ? sellerPurchase.info.tickets.map((ticket:any, index:number) => ({
        ...ticket,
        key: index.toString(),
    })) : null;

    const handlePasoAnterior = (stepNumber:number): void => {};
    const handleViewComissionText = (): void => { setViewComissionText(false);}

    return (
        <Container className='termina-comprador'>
            {sellerPurchase && !errorMessage && <div>
                <Row className='pasoscompra ps-0'>
                    <Col xs={3} className='p-0'>
                        <div className='paso paso-actual'>Realiza el Pago</div>
                    </Col>
                </Row>
                <Row className='d-flex barra_superior_evento pt-2 pb-2 align-tems-center'>
                    <Col md={4} className='d-flex align-items-center justify-content-center pe-1'><LocationOnOutlinedIcon /> {sellerPurchase.place}<br /> {sellerPurchase.location ? `${sellerPurchase.location}, ${sellerPurchase.country}` : ''}</Col>
                    <Col md={2} className='text-center border-start d-flex align-items-center justify-content-center'><WatchLaterOutlinedIcon /> {sellerPurchase.duration}</Col>
                    <Col md={2} className='text-center border-start d-flex align-items-center justify-content-center'><AccountCircleOutlinedIcon /> {sellerPurchase.classification}</Col>
                    <Col md={2} className='text-center border-start d-flex align-items-center justify-content-center text-capitalize'><BoyOutlinedIcon /> {sellerPurchase.publicPresentationPlace}</Col>
                </Row>
                <Row>
                    <Row>
                        <div><p className="h4 titulo_evento_en_paso text_2" dangerouslySetInnerHTML={{ __html: sellerPurchase.info.eventName }} /></div>
                        <div className='d-flex justify-content-center time-date-wrapper'>
                            <div><DateRangeIcon /></div>
                            <div> {sellerPurchase.info.eventDate}</div>
                            <div><WatchLaterOutlinedIcon /></div>
                            <div>{sellerPurchase.info.eventHour}</div>
                        </div>
                    </Row>
                    <Row className='mb-4'>
                        <Col md={6} className='ps-0'>
                            <TipoPagoTarjeta handlePasoAnterior={handlePasoAnterior} handleViewComissionText={handleViewComissionText} presetedCardNumber={null} purchaseCode={purchaseCode} />
                        </Col>
                        <Col md={6}>
                            <h4 className='subtitles' style={{"textAlign":"center"}}>Detalle de tu Compra</h4>
                            <div>
                            <Table className='detalle-compra-table' columns={columns} dataSource={data} pagination={false} />
                            <Row className='total-detalle-compra pt-2 pb-2'>
                                <Col xs={8} className='ps-3'> SubTotal (por cuenta y orden) </Col>
                                <Col xs={4} className='text-end'> {sellerPurchase.info.totalTickets} </Col>
                            </Row>
                            <Row className='total-detalle-compra pt-2 pb-2'>
                                <Col xs={6} className='ps-3'> Servicio Venta Internet {lang === 'es_AR' && (sellerPurchase.info.commission != "$ 0,00") && <><br/><small>(este cargo puede variar según el medio de pago)</small></>}</Col>
                                <Col xs={6} className='text-end'> {sellerPurchase.info.commission} </Col>
                            </Row>
                            {sellerPurchase.info.amountDiscount < 0 && <>
                                <Row className='total-detalle-compra pt-2 pb-2'>
                                    <Col xs={6} className='ps-3'> {sellerPurchase.info.discountName} </Col>
                                    <Col xs={6} className='text-end'>{sellerPurchase.info.totalDiscount}</Col>
                                </Row>
                            </>}
                            <Row className='total-detalle-compra pt-2 pb-2'>
                                <Col xs={6} className='ps-3'> TOTAL </Col>
                                <Col xs={6} className='text-end'> {sellerPurchase.info.totalToPaid} </Col>
                            </Row>
                            </div>
                        </Col>
                    </Row>
                </Row>
            </div>}
           {!sellerPurchase && !errorMessage &&
            <Row>
                <Col>
                    <h1 className='mt-5 text_3 general-title text-center'><b>Estamos buscando</b></h1>
                    <h1 className='text_3 general-title text-center'><b>tu Compra...</b></h1>
                    <h1 className='text_3 general-title text-center'><b>Aguarda unos segundos...</b></h1>
                    <Spinner className='text-center' style={{ "top": "40%", "left": "47%" }} />
                </Col>
            </Row>}
            {errorMessage &&
                <>
                    <div className='mt-5'></div>
                    <Row>
                        <Col xs={{ size: 4, offset: 5 }} className='mt-4'>
                            <img src="/images/icono-busqueda-01.svg" alt="icono de busqueda sin resultados" className='img-fluid icon-without-result' />
                        </Col>
                    </Row>
                    <Row>
                        <Col><h1 className='text_3 fs-22 pt-3 text-center'>¡No encontramos ningún resultado!</h1></Col>
                    </Row>
                    <Row>
                        <Col className='text-center ps-4 pe-4 fs-12 ps-5 pt-2' style={{ "lineHeight": "22px" }}>
                            <p>{errorMessage}</p>
                        </Col>
                    </Row>
                </>
            }
        </Container>
    );
};

export default TerminarCompraVendedor;