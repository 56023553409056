import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PurchaseData, datosCompra } from '../../../types/types';
import { seatWidgetInfo } from '../../../api/purchase';
import { fetchDetalleCompra } from '../../../reducer/events-reducer';
import { Col } from 'reactstrap';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Spinner from '../Spinner';
export type configObjectEventos = {
    eventsReducer: {
        datosCompraPasoDos: PurchaseData;
        detalleCompra: datosCompra
    }
}
const CompraEventoSeat = ({
    datosCompraPasoDos,
    fetchDetalleCompra,
    detalleCompra,
}: {
    datosCompraPasoDos: PurchaseData;
    fetchDetalleCompra: () => any;
    detalleCompra: datosCompra;
}) => {
    const [sitewidgetdata, setSiteWidgetData] = useState<sitewidgetData | null>(null)
    const [enableSeatWidgetTitle, setEnableSeatWidgetTitle] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            const res = await seatWidgetInfo()

            if (res) {
                const data: sitewidgetData = res;
                data.sectors.map((sector) => {
                    const resumen_precios = sector.resumen_precios.replace("€", "&euro;");
                    sector.resumen_precios = resumen_precios;
                })
                if(data.seatWidgetType === '1'){
                    window.dispatchEvent(new Event("on_seatwidget_type"));
                }

                setSiteWidgetData(data);
            }
           
            var s = document.createElement("script");
            s.type = `text/javascript`;
            s.src = `${window.location.origin}/js/seatwidget-compras-pack.min.js?v=3.3`;
            document.head.appendChild(s);

            fetchDetalleCompra()
            
        })()
    }, [fetchDetalleCompra])

    window.addEventListener("on_seatwidget_type", function changeSeatWidgetHandler(event) {
        setEnableSeatWidgetTitle(!enableSeatWidgetTitle)
    });

    type sitewidgetData = {
        sectorMediaUrl: string;
        sectorMediaDim: {
            bits: number;
            channels: number;
            mime: string;
            0: number;
            1: number;
            2: number;
            3: string;
        };
        sectors: {
            codigo: string;
            nombre: string;
            tipo: string;
            habilitado: boolean;
            resumen_precios: string;
            vector_data: {
                name: string;
                color: string;
                opacity: string;
                custom: string;
                points: {
                    x: number;
                    y: number;
                }[];
                id: string;
                record_id: string;
                bgcolor: string;
            };
        }[];
        options: {
            xorsector: boolean;
            lazysector: boolean;
            sitwidget_controles: number;
            viewer_enabled: number,
            viewer_width: number,
            viewer_mode: number,
            viewer_position: string,
            viewer_height: number,
            viewer_zoom: number
        };
        seatWidgetType: null | string;
        initialCursor: {
            zoom: number;
            x: number;
            y: number;
        };
        places: string;
        purchaseProgressCode: null | string;
        originProcess: null | string;
        firstStep: null | string;
        ajaxQueryPlaces: null | string;
        ajaxQueryPriceConsult: null | string;
        ajaxSell: null | string;
        ajaxQuerySectors: null | string;
        ajaxResetSeatWidget: null | string;
    };

    const CssString = (cssString: string) => {
        const css_json = `{"${cssString
            .replace(/; /g, '", "')
            .replace(/: /g, '": "')
            .replace(";", "")}"}`;

        const obj = JSON.parse(css_json);

        const keyValues = Object.keys(obj).map((key) => {
            var camelCased = key.replace(/-[a-z]/g, (g) => g[1].toUpperCase());
            return { [camelCased]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
    }
    // Función para observar los cambios en el estilo del elemento
    const observeSitwidgetConfirm = () => {
        const widgetConfirm = document.querySelector("#sitwidget-confirm") as HTMLElement;

        if (!widgetConfirm) {
            return;
        }

        let isFetchDetalleCompraCalled = false; // Bandera para controlar si fetchDetalleCompra se ha llamado

        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (
                    mutation.type === "attributes" &&
                    mutation.attributeName === "style"
                ) {
                    const displayValue = widgetConfirm.style.display;

                    if (displayValue === "none" && !isFetchDetalleCompraCalled) {
                        fetchDetalleCompra();
                        isFetchDetalleCompraCalled = true; // Actualizar la bandera para indicar que fetchDetalleCompra ya se ha llamado
                    }
                }
            }
        });

        observer.observe(widgetConfirm, { attributes: true });
    };

    return (
        <>
            {!sitewidgetdata && <Col className='position-relative'>
                <Spinner style={{ 'top': '50%', 'left': '40%' }} />
            </Col>}
            {sitewidgetdata &&
                <Col className='position-relative'>
                    <h4 className='subtitles text-center'>
                        <table style={{"marginLeft": "auto", "marginRight": "auto"}}>
                            <tbody>
                                {enableSeatWidgetTitle && <tr className='addPlacesTitle'>
                                    <td>Agrega tus Lugares</td>
                                    <td style={{"width": "10%"}}>&nbsp;&nbsp;&nbsp;</td>
                                    <td style={{"width": "3%"}}><div style={{"background": "#0000FF", "borderRadius": "50%", "width": "10px", "height": "10px"}}></div></td>
                                    <td style={{"fontWeight": "normal"}}>Disponible</td>
                                    <td style={{"width": "10%"}}>&nbsp;&nbsp;&nbsp;</td>
                                    <td style={{"width": "3%"}}>
                                        <div style={{"background": "#E38484", "borderRadius": "50%", "width": "10px", "height": "10px"}}></div>
                                    </td>
                                    <td style={{"width": "3%"}}><div style={{"background": "#666666", "borderRadius": "50%", "width": "10px", "height": "10px"}}></div></td>
                                    <td style={{"fontWeight": "normal"}}>No Disponible</td>
                                </tr>}
                                {!enableSeatWidgetTitle && <tr className='selectSectorTitle'>
                                    <td>Selecciona el Sector</td>
                                </tr>}
                            </tbody>
                        </table>
                    </h4>
                    <div className='seat-widget'>
                        <div className="sitwidget-container">
                            <div className="sitwidget-controls">
                                <button className="btn-reset" style={{ "display": "none" }}>
                                    <svg viewBox="0 0 64 64"><path d="M32,64C14.357,64,0,49.643,0,31.998l0.018-0.436l7.474,0.161l-0.018,0.436C7.478,45.521,18.479,56.522,32,56.522 s24.522-11.001,24.522-24.524c0-13.521-11.001-24.52-24.522-24.52c-6.247,0-17.577,1.944-23.266,14.97l-6.85-2.994 C9.548,1.895,25.497,0,32,0c17.643,0,32,14.355,32,31.998C64,49.643,49.643,64,32,64z"></path><polyline points="16.865,22.1 2.285,24.15 0.259,9.749 	"></polyline></svg>
                                </button>
                                <button className="btn-zoom-in">
                                    <svg viewBox="0 0 64 64"><rect y="29" width="64" height="6"></rect><rect x="29" width="6" height="64"></rect></svg>
                                </button>
                                <button className="btn-zoom-out">
                                    <svg viewBox="0 0 64 64"><rect x="0.001" y="29" width="64" height="6"></rect></svg>
                                </button>
                            </div>
                            <div className='cur_sel'></div>
                            <svg id='sitwidget' className='sitwidget' {...{
                                bg_size: `{"w":${sitewidgetdata.sectorMediaDim[0]},"h":${sitewidgetdata.sectorMediaDim[1]}}`,
                                lazy: `${sitewidgetdata.options.lazysector}`,
                                xor_sector_mode: `${sitewidgetdata.options.xorsector}`,
                                data: `${btoa(JSON.stringify(sitewidgetdata.sectors))}`,
                                lugares: `${sitewidgetdata.places}`,
                                tipo_sitwidget: `${sitewidgetdata.seatWidgetType}`,
                                first_time: `${sitewidgetdata.firstStep ? sitewidgetdata.firstStep : 1}`,
                                ajax_query_lugares: `${sitewidgetdata.ajaxQueryPlaces}`,
                                ajax_consulta_precio: `${sitewidgetdata.ajaxQueryPriceConsult}`,
                                ajax_consulta_sector: `${sitewidgetdata.ajaxQuerySectors}`,
                                ajax_vender: `${sitewidgetdata.ajaxSell}`,
                                ajax_reset_sitwidget: `${sitewidgetdata.ajaxResetSeatWidget}`,
                                venta_codigo: `${sitewidgetdata.purchaseProgressCode}`,
                                proceso_origen: `${sitewidgetdata.originProcess}`,
                                cursor: `${JSON.stringify(sitewidgetdata.initialCursor)}`
                            }}>
                                <image x='0' y='0' width={sitewidgetdata.sectorMediaDim[0]} height={sitewidgetdata.sectorMediaDim[1]} xlinkHref={sitewidgetdata.sectorMediaUrl} />
                            </svg>
                            <button type="button" className="close-viewer" aria-label="Close">x</button>
                            <svg id='sitwidget-viewer' className='sitwidget-viewer'
                                {...{
                                    bgw: `${sitewidgetdata.options.viewer_width}`,
                                    bgh: `${sitewidgetdata.options.viewer_height}`,
                                    zoom: `${sitewidgetdata.options.viewer_zoom}`,
                                    cursor: "{ zoom: 1, x: 0 , y: 0 }"
                                }}
                                style={CssString(sitewidgetdata.options.viewer_position)}
                                xmlns='http://www.w3.org/2000/svg' version='1.1'>
                                <image x='0' y='0' width={sitewidgetdata.options.viewer_width} height={sitewidgetdata.options.viewer_height} xlinkHref={sitewidgetdata.sectorMediaUrl} />
                                <rect className='overview' x='0' y='0' width='10' height='10' />
                            </svg>
                        </div>
                        <div id="sitwidget-confirm" style={{ "display": "none" }} className="noselect">
                            <div className='modal-compra-evento-seat modal-dialog modal-dialog-centered'>
                                <div className='modal-content'>
                                    <div className='modal-header'>
                                        <h5>ESTAS AGREGANDO UN<br /> ITEM A TU COMPRA</h5>
                                        <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                                            const widgetConfirm = document.querySelector("#sitwidget-confirm") as HTMLElement;
                                            if (widgetConfirm) {
                                                widgetConfirm.style.display = "none";
                                            }
                                        }}></button>
                                    </div>
                                    <span className="closeme cancel"></span>
                                    <div className="body">
                                        <div className="panelinfo">
                                            <p className="sector_sel sectorlugar mb-0 pb-0" style={{ "display": "none" }}>
                                                <span className="label me-2">SECTOR:</span>
                                                <strong className="info ms-2"></strong>
                                            </p>
                                            <p className="lugar_sel sectorlugar">
                                                <span className="label me-2">LUGAR:</span>
                                                <strong className="info ms-0">F-18</strong>
                                            </p>
                                        </div>
                                        <p className='fw-bold'>
                                            Selecciona Tipo de Precio:
                                        </p>
                                        <table className="price_selector">
                                        </table>
                                        <img className="loading" src="/img/loading.gif" alt="" style={{ "width": "24px" }} />
                                        <div>
                                            <button className="confirm ewb ewb-primary ewb-14 btn btn-secondary modal-buttons" onClick={() => { observeSitwidgetConfirm() }}>
                                                ACEPTAR <ArrowForwardIosIcon />
                                            </button>
                                        </div>
                                        <div className="mt-2 mb-2">
                                            <button type="button" className="cancel-bt btn btn-secondary modal-buttons" onClick={() => {
                                                const widgetConfirm = document.querySelector("#sitwidget-confirm") as HTMLElement;
                                                if (widgetConfirm) {
                                                    widgetConfirm.style.display = "none";
                                                }
                                            }}>CANCELAR </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>}
        </ >
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        datosCompraPasoDos: state.eventsReducer.datosCompraPasoDos,
        detalleCompra: state.eventsReducer.detalleCompra
    }
}
export default connect(
    mapStateToProps,
    { fetchDetalleCompra }
)(CompraEventoSeat);

