import './styles.scss';
import { Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ProductoresYSalas from './pages/ProductoresYSalas';
import { connect } from 'react-redux';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Eventos from './pages/Eventos';
import EventoSeleccionado from './pages/EventoSeleccionado';
import PagoFinalizado from './pages/PagoFinalizado';
import CompraRevalidarMercadoPago from './pages/CompraRevalidarMercadoPago';
import Test from './pages/Test';
import ErrorHandler from './components/ErrorHandler';
import Health from './pages/Health';
import CookiePolicy from "./components/cookiePolicy";
import CompraRevalidarRedSys from './pages/CompraRevalidarRedSys';
import LandingRecinto from './pages/LandingRecinto';
import VenderEntradas from './pages/VenderEntradas';
import EventosPopulares from './pages/EventosPopulares';
import SmartTicketConfirmaTransferencia from './pages/SmartTicketConfirmaTransferencia';
import TerminarCompraVendedor from './pages/TerminarCompraVendedor';
import SmartTicket from './pages/SmartTicket';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF6600',
    },
    secondary: {
      main: '#ffffff',
    },
  },
});


function App() {

  return (
    <>
    <div className="App App_mobile">
      <ThemeProvider theme={theme}>
        <Header />
        <div className='main-wrapper'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path="/actuator/health/readiness" element={<Health />}/>
            <Route path='/productores-y-salas' element={<ProductoresYSalas />} />
            <Route path='/eventos' element={<Eventos />} />
            <Route path='/evento/:hashEvent/step/:pasoCompra' element={<EventoSeleccionado />} />
            <Route path='/pago-finalizado/:checkoutType/:additionalCode?' element={<PagoFinalizado />} />
            <Route path='/pago-pendiente/:checkoutType/:paymentMethod?/:additionalCode?' element={<PagoFinalizado />} />
            <Route path='/compra-revalidar-mercadopago' element={<CompraRevalidarMercadoPago />} />
            <Route path='/compra-revalidar-redsys' element={<CompraRevalidarRedSys />} />
            <Route path='/vender-entradas' element={<VenderEntradas />} />
            <Route path='/los-30-eventos-mas-populares' element={<EventosPopulares />} />
            <Route path='/confirmar-transferencia' element={<SmartTicketConfirmaTransferencia />} />
            <Route path='/terminar-pago' element={<TerminarCompraVendedor />} />
            <Route path='/smart-ticket' element={<SmartTicket />} />
            <Route path='/:landing' element={<LandingRecinto />} />
          </Routes>
        </div>
        <ErrorHandler />
        <CookiePolicy />
        <Footer />
      </ThemeProvider>
    </div>
    </>
  );
}


export default connect()(App);
