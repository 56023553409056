import { ApiError } from "./ApiError";
import { Client } from "./baseurl";

export const executeTransfer = async(code: string) => {
    try {
        const response = await Client.get(`/transfer/${code}`);
        return response.data;    
    } catch (error:any) {
        let message = "Ocurrio un error";
        let details = undefined;
        if (error!.response!.data!.message) {
            message = error!.response!.data!.message;
            details = error!.response!.data!.details;
        }
        throw new ApiError(message, details);
    }
}

export const sellerDecidirPayWay = async(bin:string, othertoken:string, dni:string, installment:number, code: string) => {
    try {
      
      const request = {
        "bin": bin,
        "token": othertoken,
        "dni": dni,
        "installment": installment
      };
  
      const response = await Client.post(`seller/pay-way/process?code=${code}`, request);
  
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Error en la respuesta del servidor");
      }
    } catch (error:any) {
      // Aquí manejas el error del servidor
      let message = "Ocurrio un error";
      let details = undefined;
      if (error!.response!.data!.message) {
        message = error!.response!.data!.message;
        details = error!.response!.data!.details;
      }
      throw new ApiError(message, details);
    }
  };

  export const sellerProccessPaymentMercadoPago = async(formData:any, code: string) => {
    const response = await Client.post(`seller/mp/process?code=${code}`,formData);
    return response.data;
  }
  